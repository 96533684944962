<template>
    <div>
        <vue-title title="Первичный документ | KEL.KZ"></vue-title>
        <Header :routes="headerRoutes.defaultsoutes"/>
        <div class="container py-4">
            <div class="long-title text-center mb-3">
                <h1 class="page-title">{{primaryDocumentName ? primaryDocumentName : ''}}</h1>
            </div>
            <div class="row">
                <div class="col-12">
                    <div v-if="!primaryDocument">
                        <DxLoadIndicator
                                id="medium-indicator"
                                :height="20"
                                :width="20"
                        />
                        Загрузка
                    </div>
                    <div v-if="primaryDocument">
                        <div v-if="primaryDocumentName == ''">
                            {{ primaryDocument.primaryDocumentKind }}
                        </div>
                        <ContractAppCarrierAInfo
                                v-if="primaryDocument.primaryDocumentKind == 'pdContractAppCarrierA'"
                                :doc="primaryDocument"
                                :Agent="Agent"
                                :getPrimaryDocument="getPrimaryDocument"
                        />
                    </div>
                </div>
            </div>
        </div>
        <Footer/>
    </div>
</template>

<script>
    import headerRoutes from './header-routes';
    import {mapGetters} from 'vuex';
    import {Header, Footer} from '../../shared/components';
    import {DxLoadIndicator} from 'devextreme-vue/load-indicator';
    import localStore from './store';

    import ContractAppCarrierAInfo from './detail/ContractAppCarrierAInfo';

    export default {
        name: "PrimaryDocsPrimaryDocument",
        components: {
            Header,
            Footer,
            DxLoadIndicator,
            ContractAppCarrierAInfo
        },
        data() {
            return {
                headerRoutes,
                primaryDocumentId: this.$route.params.primaryDocumentId,
                primaryDocument: null,
                primaryDocumentName: ''
            }
        },
        mounted() {
            this.getPrimaryDocument();
        },
        methods: {
            getPrimaryDocument() {
                this.$store.dispatch('loading', true).then(() => {
                    localStore.dispatch('getPrimaryDocument', {tokenId: this.Agent.tokenId, primaryDocumentId: this.primaryDocumentId}).then(() => {
                        this.primaryDocument = localStore.state.primaryDocument;

                        switch (this.primaryDocument.primaryDocumentKind) {
                            case 'pdContractAppCarrierA':
                                this.primaryDocumentName = this.primaryDocument.primaryDocumentCaption;
                                break;
                        }

                        this.$store.dispatch('loading', false);
                    }).catch(this.handleError);
                });
            },
            handleError: function (error) {
                console.error(error);
                if(error.response.status == 404) {
                    this.$router.push({name: 'Error404'});
                }
            },
        },
        computed: {
            ...mapGetters([
                'isLoading',
                'Agent'
            ])
        },
    }
</script>

<style scoped>

</style>
